import React from 'react';
import ReactDOM from 'react-dom';
import App from './Components/App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

//import fonts
import './Components/Css/Fonts/IRANSansLight.ttf';
import './Components/Css/Fonts/IRANSansBold.ttf';

import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';

// import 'rayconnect-client/dist/public-api'


// try{

//     window.RayconnectInit({
//        // scopes:"test,project,edge,dns,container,file,db,paas",
//         scopes:"",
//         appID:"panel",
//         space:"main",
//         type:"client",
//         url:"https://test-net.rc0.ir"
//     })

//     window.RayconnectApp.OnConnect(async ()=>{

//         // window.RayconnectApp.GetUserAccess({
//         //     username:"panel_admin_system",
//         //     password:"o3XoSQMFSXuIcc8oxSD9LSXKaR8xh6Ih5d3C9rbT6Sz"
//         // })


//         if(localStorage['rayconnect-client-panel-token']){
//             window.RayconnectApp.Auth(localStorage['rayconnect-client-panel-token'])

//         }else {
//             window.RayconnectApp.GetGuestAccess();

//         }


//     })

// }catch (e) {
//     console.log(e)
// }


// Sentry.init({
//     dsn: "https://1a9cb963384b4c26bd0971f858fd24f4@o924427.ingest.sentry.io/6055899",
//     integrations: [new Integrations.BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

ReactDOM.render(<App  /> , document.getElementById('root'))


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
