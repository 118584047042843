import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import swal from 'sweetalert';
import { Form } from "react-bootstrap";
import LoginBack from "../img/loginBack.jpg"


class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            items: [],
            BridgeSocket: this.props.BridgeSocket,
            redirect: true
        };

    }


    setshow(value) {
        return value
    }

    handleChangePassword(e) {

        this.setState({ password: e.target.value });
    }


    handleChangePasswordAgain(e) {

        this.setState({ passwordagain: e.target.value });
    }

    handleChangePhone(e) {

        this.setState({ phone: e.target.value });
    }

    handleChangeUsername(e) {
        this.setState({ username: e.target.value });
    }


    ShowSucMessage(e) {
        swal(e);
    }

    ShowloginMessage(e, s) {
        swal(e, '', s);
    }


    handleChangeEmail(e) {

        this.setState({ email: e.target.value });
    }


    componentWillUnmount() {
        // stop sending ajax when client in other page
        this.mounted = false;

    }

    componentDidMount(props) {
        document.title = "ثبت نام"
        this.mounted = true;
        this.setState({
            isLoaded: true,
        });

    }

    getCookie(name) {
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }


    async LoginHandler(e) {
        //   this.setState({ fromInput: e.target.value })

        e.preventDefault();
        if (this.state.email === "" && this.state.password === "" && this.state.name === "") {
            this.ShowloginMessage('اطلاعاتی وارد نشده', 'error')
        } else {
            this.ShowloginMessage('در حال ثبت نام', 'info');


            if (this.mounted) {



                const BridgeSocket = await this.state.BridgeSocket

                BridgeSocket.emit('register', {
                    username: this.state.username,
                    phone: this.state.phone,
                    email: this.state.email,
                    password: this.state.password,
                    app_id: 'demopanel'
                })

                BridgeSocket.on('register/' + BridgeSocket.id , (msg) => {
                    
                    if (this.state.password != this.state.passwordagain) {
                        this.ShowloginMessage('تکرار رمز عبور به درستی وارد نشده', 'error');
                    } else {
    
                        if (msg.message.username != undefined) {
    
                            this.ShowloginMessage('ثبت نام با موفقیت انجام شد', 'success');
                            this.props.history.push("/login");
    
                        } else {
                                this.ShowloginMessage('این اطلاعات قبلا ثبت شده است', 'error');
                        }
                    }
                })

            }
        }

    }


    render() {

        const theme = createTheme({
            direction: 'rtl',
            typography: {
                fontFamily: [
                    'GoldmanLight',
                ].join(','),
            },
        });

        return (



            <div style={{ height: '100vh' }}>
                <ThemeProvider theme={theme}>
                    <Grid container component="main" sx={{ height: '100vh' }}>
                        <CssBaseline />
                        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                            <Box
                                sx={{
                                    my: 8,
                                    mx: 4,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    paddingTop: '3rem'
                                }}
                            >
                                {/*  <Avatar align='center' style={{textAlign:'center'}} sx={{m: 1, bgcolor: 'secondary.main',textAlign:'center'}}>
                                    <LockOutlinedIcon/>
                                </Avatar>*/}
                                <Typography component="h1" variant="h5">
                                    ثبت نام
                                </Typography>
                                <Box component="form" noValidate sx={{ mt: 1 }}>

                                    <Form style={{ textAlign: 'right' }}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>شماره موبایل</Form.Label>
                                            <Form.Control style={{ border: '1px solid #2860fc' }} type="phone"
                                                placeholder="09123456789"
                                                onChange={this.handleChangePhone.bind(this)} required />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>ایمیل</Form.Label>
                                            <Form.Control style={{ border: '1px solid #2860fc' }} type="email"
                                                placeholder="ایمیل را وارد کنید"
                                                onChange={this.handleChangeEmail.bind(this)} required />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>نام کاربری</Form.Label>
                                            <Form.Control style={{ border: '1px solid #2860fc' }} type="username"
                                                placeholder="نام کاربری را وارد کنید"
                                                onChange={this.handleChangeUsername.bind(this)} required />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>رمز عبور</Form.Label>
                                            <Form.Control style={{ border: '1px solid #2860fc' }} type="password"
                                                placeholder="بیشتر از ۸ کاراکتر"
                                                onChange={this.handleChangePassword.bind(this)} required />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>تکرار رمز عبور</Form.Label>
                                            <Form.Control style={{ border: '1px solid #2860fc' }} type="password"
                                                placeholder="رمز عبور را وارد کنید"
                                                onChange={this.handleChangePasswordAgain.bind(this)} required />
                                        </Form.Group>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={this.LoginHandler.bind(this)}
                                        >
                                            ثبت نام
                                        </Button>
                                    </Form>

                                    <Grid container style={{ width: '20rem', textAlign: 'center' }}>
                                        <Grid item style={{ textAlign: 'center' }}>
                                            <Link as={Link}
                                                to={'/login'} style={{ textAlign: 'center' }} variant="body2">
                                                {"ورود"}
                                            </Link>
                                        </Grid>
                                    </Grid>

                                </Box>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={false}
                            sm={4}
                            md={7}
                            sx={{
                                backgroundImage: `url(${LoginBack})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: (t) =>
                                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        />
                    </Grid>
                </ThemeProvider>
            </div>

        );
    }
}

//Date(item.time)


export default Register;
