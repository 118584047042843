import * as React from 'react';
import {Component} from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from '@mui/material/Button';
import {Link} from "react-router-dom";

class PleaseSelectProject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            openBar: false,
            projects: props.projects,
        };
    }

    async componentDidMount() {
        if (window.innerWidth < 700) {
            window.RayconnectApp.localEvents.emit("toggleDrawer", true);
        }
    }

    render() {

        const {openBar, projects} = this.state;

        return (
            <div>
                <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}  lg={12}>
                            <Paper
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: 'auto',
                                    textAlign: 'right'
                                }}>
                                <h4 align='right'>لطفا یک پروژه انتخاب کنید یا بسازید</h4>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    }
}

export default PleaseSelectProject;