import React, { Component } from 'react';

import Index from './Index';
import Notfound from "./Notfound";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Logout from "./Auth/Logout";
import IndexDashboard from "./Panel/IndexDashboard";
import ReactSession from './ReactSession';

import 'font-awesome/css/font-awesome.min.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import {SocketContext, socket} from '../Components/context/socket';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auth: '',
            token: '',
            testText: 'kooshan'
        };
        this.mounted = true;
    }

    getCookie(name) {
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }


    redirectToLogin(name) {
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }


    componentDidMount() {

        ReactSession.setStoreType("cookie");
        ReactSession.set("url", '*');

        this.setState({
            auth: true,
        });
    }


    render() {
        
      //const io = require('socket.io-client')
      //  const BridgeSocket = io.connect('', {});

        return (

            <SocketContext.Provider value={socket}>
            < Router>


                <Switch>

                    {this.state.auth &&
                        <Route path="/panel/dashboard" component={IndexDashboard} />
                    }
                    {this.state.auth &&
                        <Route path="/panel/tickets/list" component={IndexDashboard} />
                    }
                    {this.state.auth &&
                        <Route path="/panel/tickets/create" component={IndexDashboard} />
                    }
                    {this.state.auth &&
                        <Route path="/panel/tickets/detail" component={IndexDashboard} />
                    }

                    <Route path="/logout" component={Logout} />
                    {/*<Route path="/coins/:id" component={CoinShow}/>*/}
                    {/* <Route exact path="/login" component={() => (<Login BridgeSocket={BridgeSocket} />)} /> */}
                       <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />

                    <Route exact path="/" component={Index} />

                    <Route path="" component={Notfound} />

                </Switch>

            </Router>
            </SocketContext.Provider>
        )
    }
}

export default App;
