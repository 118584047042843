import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NavbarList from './NavbarList';
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import { Component } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { socket } from './../../context/socket';
import CryptoJS from 'crypto-js'

class HeaderPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            data: '',
            count: props.count,
            open: true,
            isLoaded: true,
            title: props.title,
            projects: [],
            selectedProject: '',
            projectId: localStorage.projectId,
            AlertSuccess: false,
            AlertError: false,
            emptyInput: false,
            AlertCreate: false,
            toggleDrawer: false,
            AlertErrorMessage: false,
            AlertErrorMessageText: '',
            themeColor: '#2860FC',
            notifPage: false,
            anchorEl: null,
            anchorElProfile: null,
            setAnchorEl: null,
            userClickToggleDrawe: false,
            ProfilePage: false,
            BillPage: false,
            AlertCustom: false,
            AlertCustomMessage: null,
            hidePage: false,
            BridgeSocket: props.BridgeSocket,
        };
        this.mounted = true;
    }

    componentWillMount() {

    }

    getCookie(name) {
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }

    handleNotif() {
        if (this.state.AlertSuccess === false) {
            this.setState({
                AlertSuccess: true,
            })
        } else {
            this.setState({
                AlertSuccess: false,
            })
        }
    }

    handleNotifCreate() {
        if (this.state.AlertCreate === false) {
            this.setState({
                AlertCreate: true,
            })
        } else {
            this.setState({
                AlertCreate: false,
            })
        }
    }

    handleNotifError() {
        if (this.state.AlertError === false) {
            this.setState({
                AlertError: true,
            })
        } else {
            this.setState({
                AlertError: false,
            })
        }
    }

    handlenofitPage() {
        if (this.state.notifPage === false) {
            this.setState({
                notifPage: true,
            })
        } else {
            this.setState({
                notifPage: false,
            })
        }
    }

    handleNotifErrorMessage() {
        if (this.state.AlertErrorMessage === false) {
            this.setState({
                AlertErrorMessage: true,
            })
        } else {
            this.setState({
                AlertErrorMessage: false,
            })
        }
    }

    handleNotifErrorEmpty() {
        if (this.state.emptyInput === false) {
            this.setState({
                emptyInput: true,
            })
        } else {
            this.setState({
                emptyInput: false,
            })
        }
    }

    handleNotifCustom() {
        if (this.state.AlertCustom === false) {
            this.setState({
                AlertCustom: true,
            })
        } else {
            this.setState({
                AlertCustom: false,
            })
        }
    }

    async componentWillMount() {

    }

    async componentWillUnmount() {
        if (window.innerWidth < 900) {
            await this.toggleDrawer();
            await this.setState({
                toggleDrawer: true
            })
        }

        // const that = await this
        // await this.changeColorBottonDrawer(that)

    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    async componentDidMount() {

        setTimeout(async () => {
            //username
            const usernameBytes = await CryptoJS.AES.decrypt(await localStorage.user1, 'my-secrwehewhet-key@wjewjwej123');
            const usernameDecryptedData = await JSON.parse(await usernameBytes.toString(CryptoJS.enc.Utf8));
            //password 
            const PasswordBytes = await CryptoJS.AES.decrypt(await localStorage.user2, 'my-secrwehewhet-key@wjewjwej123');
            const PasswordDecryptedData = await JSON.parse(await PasswordBytes.toString(CryptoJS.enc.Utf8));

            const objectInput = await {
                email: await usernameDecryptedData,
                password: await PasswordDecryptedData,
                objectReq: 1
            }

            socket.on('auth/' + socket.id + '/' + 1, async (msg) => {
                console.log(msg.token)
                await localStorage.setItem("io", await msg.token)
            })

            //for check token and role
            socket.on("error/" + await socket.id, (msg) => {

                if (msg.message == 'role not correct') {
                    socket.emit('auth', objectInput)
                }
                if (msg.message == 'token not correct') {
                    socket.emit('auth', objectInput)
                }
            });

            await socket.emit('message', {
                app_id: 'demopanel',
                address: 'title/set/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                // side: {
                //   side: 'frontend',
                // },
                data: {}
            })

            socket.on("message/demopanel/title/set/" + await socket.id + '/user', async (msg) => {
                if (msg.data.message != undefined) {
                    document.title = await msg.data.message
                    await this.setState({ title: await msg.data.message })
                }
            });

        }, 3000);

        setTimeout(() => {
            this.setState({ isLoaded: false })
        }, 2000);

        setInterval(async () => {
            if (window.innerWidth < 900 && this.state.open && !this.state.userClickToggleDrawe) {
                await this.toggleDrawer();
                await this.setState({
                    toggleDrawer: true
                })
            }
            //  const that = this
            //  this.changeColorBottonDrawer(that)
        }, 1000);

        try {

            setTimeout(async () => {
                socket.on("message/demopanel/toggleDrawer/set/" + await socket.id + '/user', (msg) => {
                    this.toggleDrawer();
                });
            }, 2000);


            setTimeout(async () => {

                socket.emit('message', {
                    app_id: 'demopanel',
                    address: 'closeDrawer/set/' + await socket.id,
                    role: 'user',
                    token: await localStorage.io,
                    // side: {
                    //   side: 'frontend',
                    // },
                    data: {
                        message: ''
                    }
                })
            }, 4000);

            setTimeout(async () => {
                socket.on("message/demopanel/closeDrawer/set/" + await socket.id + '/user', async (msg) => {
                    console.log('closeDrawer')

                    if (this.state.userClickToggleDrawe) {

                        this.toggleDrawer();

                        socket.emit('message', {
                            app_id: 'demopanel',
                            address: 'hidePageWhenMobileDrawerOpen/set/' + socket.id,
                            role: 'user',
                            token: await localStorage.io,
                            data: {
                                message: false
                            }
                        })
                    }
                });

                socket.emit('message', {
                    app_id: 'demopanel',
                    address: 'hidePageWhenMobileDrawerOpen/set/' + socket.id,
                    role: 'user',
                    token: await localStorage.io,
                    data: {
                        message: ''
                    }
                })
            }, 2000);

            setTimeout(async () => {
                socket.on("message/demopanel/hidePageWhenMobileDrawerOpen/set/" + await socket.id + '/user', status => {
                    if (status.message != '') {
                        console.log(status.data.message)
                        if (status.data.message) {
                            this.setState({ hidePage: true })
                        } else {
                            this.setState({ hidePage: false })
                        }
                    }
                    console.log('user call hide page')
                });

                socket.on("message/demopanel/alert/set/" + await socket.id + '/user', async (data) => {

                    console.log(data.data)

                    if (data.data.status != undefined)
                        if (await data.data.status === 'success') {
                            this.setState({ AlertSuccess: true })
                        }
                    if (await data.data.status === 'error') {
                        this.setState({ AlertError: true })
                    }
                    if (await data.data.status === 'empty') {
                        this.setState({ emptyInput: true })
                    }
                    if (await data.data.status === 'created') {
                        this.setState({ AlertCreate: true })
                    }
                    if (await data.data.status === 'custom') {
                        if (window.innerWidth < 900) {
                            this.setState({
                                AlertCustom: true,
                                AlertCustomMessage: data.message
                            })
                        }
                    }

                });
            }, 2000)

            // await window.RayconnectApp.isAuth();
            // const uid = window.RayconnectApp.user.uid;

            // if (uid === 'guest') {
            //     window.location.replace("/login");
            // }

        } catch (e) {
            console.log(e)
        }

        if (window.innerWidth < 700) {
            await this.toggleDrawer();
        }

    }

    setProjectId(e) {
        this.setState({
            selectedProject: e.target.value,
            projectId: e.target.value
        })
        try {
            //  window.RayconnectApp.localEvents.emit('selectedProject', e.target.value)
        } catch (e) {
            console.log(e)
        }
        localStorage.projectId = e.target.value;
    }

    async changeColorBottonDrawer(that) {
        try {
            // change color bottom drawer
            const changeBotColor = await document.getElementsByClassName("MuiDrawer-paper");
            await changeBotColor[0].style.setProperty('background-color', that.state.themeColor, 'important');

            const changeBotColorOther = await document.getElementsByClassName("MuiDrawer-docked");
            await changeBotColorOther[0].style.setProperty('background-color', that.state.themeColor, 'important');

            console.log('color changed')
        } catch (e) {
            console.log(e)
        }
    }

    async toggleDrawer() {
        if (this.state.open === false) {
            await this.setState({
                open: true,
                userClickToggleDrawe: true
            })

        } else {
            await this.setState({
                open: false,
                userClickToggleDrawe: false
            })
        }
        // change color bottom drawer
        // const x = await document.getElementsByClassName("MuiDrawer-paper");
        // await x[0].style.setProperty('background-color', this.state.themeColor, 'important');
    }

    async clickOnToggleButton() {
        if (window.innerWidth < 900) {

            socket.emit('message', {
                app_id: 'demopanel',
                address: 'hidePageWhenMobileDrawerOpen/set/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    message: true
                }
            })

        }
        console.log('clickOnToggleButton')
        this.toggleDrawer()
    }

    async clickOnToggleButtonClose() {
        socket.emit('message', {
            app_id: 'demopanel',
            address: 'hidePageWhenMobileDrawerOpen/set/' + await socket.id,
            role: 'user',
            token: await localStorage.io,
            data: {
                message: false
            }
        })
        this.toggleDrawer()
    }

    render() {

        const drawerWidth = 240;

        const AppBar = styled(MuiAppBar, {
            shouldForwardProp: (prop) => prop !== 'open',
        })(({ theme, open }) => ({
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            ...(open && {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            }),
        }));

        const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
            ({ theme, open }) => ({
                '& .MuiDrawer-paper': {
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    width: drawerWidth,
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    boxSizing: 'border-box',
                    ...(!open && {
                        overflowX: 'hidden',
                        transition: theme.transitions.create('width', {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                        }),
                        width: theme.spacing(7),
                        [theme.breakpoints.up('sm')]: {
                            width: theme.spacing(9),
                        },
                    }),
                },
            }),
        );

        const {
            title,
            open,
            projects,
            projectId,
            toggleDrawer,
            AlertSuccess,
            AlertError,
            emptyInput,
            AlertCreate,
            AlertCustom,
            AlertCustomMessage,
            AlertErrorMessageText,
            AlertErrorMessage,
            themeColor,
            anchorEl,
            anchorElProfile,
            notifPage,
            ProfilePage,
            BillPage,
            hidePage,
            isLoaded
        } = this.state;


        const handleClickDropDownNotif = (event) => {
            this.setState({
                anchorEl: event.currentTarget,
                notifPage: true
            })
            // const that = this
            // this.changeColorBottonDrawer(that)
        };

        const handleCloseDropDownNotif = () => {
            this.setState({
                anchorEl: null,
                notifPage: false
            })
            // const that = this
            // this.changeColorBottonDrawer(that)
        };

        const handleClickDropDownProfile = (event) => {
            this.setState({
                anchorElProfile: event.currentTarget,
                ProfilePage: true
            })
            // const that = this
            // this.changeColorBottonDrawer(that)
        };

        const handleCloseDropDownProfile = () => {
            this.setState({
                anchorElProfile: null,
                ProfilePage: false
            })
            // const that = this
            // this.changeColorBottonDrawer(that)
        };

        const handleClickDropDownBill = (event) => {
            this.setState({
                anchorEl: event.currentTarget,
                BillPage: true
            })
            // const that = this
            // this.changeColorBottonDrawer(that)
        };

        const handleCloseDropDownBill = () => {
            this.setState({
                anchorEl: null,
                BillPage: false
            })
            // const that = this
            // this.changeColorBottonDrawer(that)
        };

        const options = [
            'اعتبار کیف پول شما رو به اتمام هست',
            'سرویس PaaS شما ساخته شد',
            'پروژه جدید شما متوقف شد',
        ];
        const ITEM_HEIGHT = 48;

        if (isLoaded) {
            return <div></div>;
        } else {

            return (


                <div dir="rtl">
                    {!hidePage &&
                        <AppBar position="absolute" open={open} sx={{
                            marginLeft: '0',
                            right: 'auto',
                            left: '0',
                            textAlign: 'right',
                            backgroundColor: 'white',
                            color: this.state.themeColor + '!important'
                        }}>
                            <Toolbar
                                sx={{
                                    pr: '24px', // keep right padding when drawer closed
                                    marginLeft: '0',
                                    right: 'auto',
                                    left: '0',
                                    textAlign: 'right'
                                }}
                            >
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={this.clickOnToggleButton.bind(this)}
                                    sx={{
                                        ...(open && { display: 'none' }),
                                    }}
                                >
                                    <MenuIcon />
                                </IconButton>

                                {!toggleDrawer &&
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        color="inherit"
                                        noWrap
                                        sx={{ flexGrow: 1, textAlign: 'right', paddingRight: '0.5rem' }}
                                    >
                                        {title}
                                    </Typography>
                                }

                                {toggleDrawer &&
                                    <Typography
                                        variant="subtitle2"
                                        color="inherit"
                                        noWrap
                                        sx={{ flexGrow: 1, textAlign: 'right', paddingRight: '0.5rem' }}
                                    />
                                }

                                {/*  bill page*/}
                                {!toggleDrawer &&
                                    <div>
                                        <IconButton
                                            color="inherit"
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls="menu-appbar"
                                            aria-expanded={BillPage ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleClickDropDownBill}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >

                                            <Button variant="outlined"
                                                style={{ borderColor: this.state.themeColor, color: this.state.themeColor }}>10,500
                                                ریال</Button>

                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={BillPage}
                                            onClose={handleCloseDropDownBill}
                                            style={{
                                                marginTop: '2rem',
                                                marginLeft: '19rem'
                                            }}
                                        >

                                            <Link as={Link} to="/panel/profile" onClick={handleCloseDropDownBill}>
                                                <MenuItem sx={{ color: 'black' }}>
                                                    افزایش اعتبار
                                                </MenuItem>
                                            </Link>

                                            <Link as={Link} to="/panel/profile" onClick={handleCloseDropDownBill}>
                                                <MenuItem sx={{ color: 'black' }}>
                                                    فاکتور ها
                                                </MenuItem>
                                            </Link>

                                        </Menu>
                                    </div>
                                }

                                {/* {!toggleDrawer &&
                                    <Link as={Link} to="/panel/project/add"><Button
                                        style={{
                                            color: 'white',
                                            marginRight: '0.5rem',
                                            width: 'max-content',
                                            backgroundColor: this.state.themeColor
                                        }}
                                        variant="contained">ایجاد پروژه</Button></Link>
                                }


                                <Dropdown style={{
                                    paddingLeft: '1rem',
                                    marginRight: '0.7rem',
                                }}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic"
                                        style={{
                                            backgroundColor: this.state.themeColor,
                                            borderColor: this.state.themeColor,
                                            textAlign: 'center',
                                            color: 'white',
                                        }}>
                                        {this.state.projectId === undefined ? 'پروژه انتخاب نشد' : projectId}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {projects.map((x) =>
                                            <Dropdown.Item as="button"
                                                style={{ backgroundColor: this.state.themeColor + '!important' }}
                                                onClick={this.setProjectId.bind(this)}
                                                value={x.aid}>{x.name}</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown> */}


                                <div>
                                    <IconButton
                                        color="inherit"
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls="long-menu"
                                        aria-expanded={notifPage ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClickDropDownNotif}
                                    >
                                        <Badge badgeContent={4} color="secondary">
                                            <NotificationsIcon />
                                        </Badge>
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={notifPage}
                                        onClose={handleCloseDropDownNotif}
                                        style={{
                                            marginTop: '2rem'
                                        }}
                                        PaperProps={{
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5,
                                                width: '20rem',
                                            },
                                        }}
                                    >
                                        {options.map((option) => (
                                            <MenuItem key={option} onClick={handleCloseDropDownNotif}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </div>

                                {/*  profile notif*/}

                                <div>
                                    <IconButton
                                        color="inherit"
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls="long-menu"
                                        aria-expanded={ProfilePage ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClickDropDownProfile}
                                    >
                                        <Badge badgeContent={0} color="secondary">
                                            <AccountCircleIcon />
                                        </Badge>
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorElProfile}
                                        open={ProfilePage}
                                        onClose={handleCloseDropDownProfile}
                                        style={{
                                            marginTop: '2rem'
                                        }}
                                    >

                                        <Link as={Link} to="/panel/profile" onClick={handleCloseDropDownProfile}>
                                            <MenuItem sx={{ color: 'black' }}>
                                                پروفایل
                                            </MenuItem>
                                        </Link>

                                        <Link as={Link} to="/panel/logout" onClick={handleCloseDropDownProfile}>
                                            <MenuItem sx={{ color: 'black' }}>
                                                خروج
                                            </MenuItem>
                                        </Link>

                                    </Menu>
                                </div>

                            </Toolbar>
                        </AppBar>
                    }
                    <Drawer variant="permanent" open={open}
                        sx={{ backgroundColor: this.state.themeColor + '!important', height: '100vh' }}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                                backgroundColor: this.state.themeColor + '!important',
                                textAlign: 'right'
                            }}
                        >
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1, textAlign: 'right', paddingRight: '1rem', color: 'white' }}
                            >
                                <Link as={Link} style={{ color: 'white' }} to="/">
                                    پنل رایدا
                                </Link>
                            </Typography>
                            <IconButton style={{ marginRight: '0!important' }}
                                onClick={this.clickOnToggleButtonClose.bind(this)}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List sx={{
                            backgroundColor: this.state.themeColor + '!important',
                            color: 'white',
                            paddingBottom: { xs: '9rem', md: '12rem' }
                        }}><NavbarList
                                projects={projects} themeColor={themeColor} /></List>
                    </Drawer>

                    {
                        AlertSuccess === true &&
                        <Snackbar open={this.handleNotif.bind(this)} autoHideDuration={6000}
                            onClose={this.handleNotif.bind(this)}>
                            <Alert onClose={this.handleNotif.bind(this)} severity="success"
                                sx={{ width: '100%', textAlign: 'left' }}>
                                با موفقیت ذخیره شد
                            </Alert>
                        </Snackbar>
                    }

                    {
                        AlertCreate === true &&
                        <Snackbar open={this.handleNotifCreate.bind(this)} autoHideDuration={6000}
                            onClose={this.handleNotifCreate.bind(this)}>
                            <Alert onClose={this.handleNotifCreate.bind(this)} severity="success"
                                sx={{ width: '100%', textAlign: 'left' }}>
                                با موفقیت ایجاد شد
                            </Alert>
                        </Snackbar>
                    }

                    {
                        AlertError === true &&
                        <Snackbar open={this.handleNotifError.bind(this)} autoHideDuration={6000}
                            onClose={this.handleNotifError.bind(this)}>
                            <Alert onClose={this.handleNotifError.bind(this)} severity="error"
                                sx={{ width: '100%', textAlign: 'left' }}>
                                به مشکل برخوردم در ایجاد سرویس
                            </Alert>
                        </Snackbar>
                    }

                    {
                        AlertErrorMessage === true &&
                        <Snackbar open={this.handleNotifErrorMessage.bind(this)} autoHideDuration={6000}
                            onClose={this.handleNotifErrorMessage.bind(this)}>
                            <Alert onClose={this.handleNotifErrorMessage.bind(this)} severity="error"
                                sx={{ width: '100%', textAlign: 'left' }}>
                                {AlertErrorMessageText}
                            </Alert>
                        </Snackbar>
                    }

                    {
                        emptyInput === true &&
                        <Snackbar open={this.handleNotifErrorEmpty.bind(this)} autoHideDuration={6000}
                            onClose={this.handleNotifErrorEmpty.bind(this)}>
                            <Alert onClose={this.handleNotifErrorEmpty.bind(this)} severity="error"
                                sx={{ width: '100%', textAlign: 'left' }}>
                                مقادیر به درستی وارد نشده یا خالی است
                            </Alert>
                        </Snackbar>
                    }

                    {
                        AlertCustom === true &&
                        <Snackbar open={this.handleNotifCustom.bind(this)} autoHideDuration={3000}
                            onClose={this.handleNotifCustom.bind(this)}>
                            <Alert onClose={this.handleNotifCustom.bind(this)} severity="info"
                                sx={{ width: '100%', textAlign: 'left' }}>
                                {AlertCustomMessage}
                            </Alert>
                        </Snackbar>
                    }


                </div>

            );
        }
    }
}

export default HeaderPanel;
