import React, {Component} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


class Loading extends Component {


    render() {

        return (

            <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
                <Grid container spacing={3}>
                    <Box sx={{display: 'flex', margin: 'auto', paddingTop:'15rem'}}>
                        <CircularProgress/>
                    </Box>
                </Grid>
            </Container>

        );
    }

}


export default Loading;
