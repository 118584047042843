import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Component } from "react";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

//icons
import LanguageIcon from '@mui/icons-material/Language';
import DnsIcon from '@mui/icons-material/Dns';
import SdStorageIcon from '@mui/icons-material/SdStorage';
import StorageIcon from '@mui/icons-material/Storage';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import BackupIcon from '@mui/icons-material/Backup';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import WebIcon from '@mui/icons-material/Web';
import { socket } from './../../context/socket';

class NavbarList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            openBar: false,
            projects: props.projects,
            themeColor: props.themeColor,
        };
    }

    async handle() {
        if (this.state.openBar === false) {
            this.setState({
                openBar: true
            })
        } else {
            this.setState({
                openBar: false
            })
        }

        const that = this
        await this.handleCloseDrawer(that)
    };

    componentWillReceiveProps(props) {
        this.state = {
            projects: props.projects
        };
    }

    async componentDidMount() {

    }

    async handleCloseDrawer() {

        //  window.RayconnectApp.localEvents.emit('changeColorBottomDrawer', true)
        if (window.innerWidth < 900) {

            await socket.emit('message', {
                app_id: 'demopanel',
                address: 'closeDrawer/set/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    message: true
                }
            })

            //  window.RayconnectApp.localEvents.emit('closeDrawer', true)
        }
    }

    render() {

        const { openBar, projects, themeColor } = this.state;

        return (
            <div dir="rtl">
                <ListItem button sx={{
                    '&:hover': {
                        color: '#3f3c3b',
                    }, textDecoration: 'none', textAlign: 'right'
                }}>
                    <ListItemIcon as={Link} to="/panel/dashboard" style={{ textDecoration: 'none' }}
                        onClick={this.handleCloseDrawer}>
                        <DashboardIcon sx={{
                            '&:hover': {
                                color: '#3f3c3b',
                            }, '&:after': {
                                color: '#3f3c3b',
                            }
                        }} />
                    </ListItemIcon>
                    <ListItemText style={{ color: 'white', textDecoration: 'none' }} as={Link} to="/panel/dashboard"
                        onClick={this.handleCloseDrawer} primary="داشبورد" />
                </ListItem>

                {(window.innerWidth > 700) &&
                    <ListItem onClick={this.handle.bind(this)} button sx={{ textDecoration: 'none', textAlign: 'right' }}>
                        <ListItemIcon>
                            <ShoppingCartIcon sx={{
                                '&:hover': {
                                    color: '#3f3c3b',
                                }, '&:after': {
                                    color: '#3f3c3b',
                                }
                            }} />
                        </ListItemIcon>
                        <ListItemText primary="تست ها" />
                    </ListItem>
                }

                {openBar &&
                    <ListItem onClick={this.handle.bind(this)} as={Link} to="/panel/project/add" button
                        sx={{
                            '&:hover': {
                                color: '#3f3c3b',
                            }, textAlign: 'right', textDecoration: 'none'
                        }}>
                        <ListItemText style={{
                            '&:hover': {
                                color: '#3f3c3b',
                            },
                            textDecoration: 'none',
                            color: 'white',
                            backgroundColor: this.state.themeColor + '!important'
                        }} as={Link} to="/panel/project/add" primary="ایجاد تست" />
                    </ListItem>
                }
                {openBar && projects.map((x) =>
                    <ListItem sx={{
                        '&:hover': {
                            color: '#3f3c3b',
                        },
                    }} onClick={this.handle.bind(this)} button as={Link}
                        to={'/panel/project/apps?id=' + x.aid} sx={{ textDecoration: 'none', textAlign: 'right', color: 'white' }}>
                        <ListItemText primary={x.name} style={{ textDecoration: 'none', color: 'white' }} as={Link}
                            to={'/panel/project/apps?id=' + x.aid} />
                    </ListItem>
                )}


                <Divider />
                {/*<ListSubheader inset sx={{backgroundColor: '#2860FC', color: 'white'}}>سرویس ها</ListSubheader>*/}

                <ListItem button sx={{
                    '&:hover': {
                        color: '#3f3c3b',
                    }, textAlign: 'right', textDecoration: 'none'
                }}>
                    <ListItemIcon as={Link} to="/panel/tickets/list" style={{ textDecoration: 'none' }}
                        onClick={this.handleCloseDrawer}>
                        <StorageIcon sx={{
                            '&:hover': {
                                color: '#3f3c3b',
                            }, '&:after': {
                                color: '#3f3c3b',
                            }
                        }} />
                    </ListItemIcon>
                    <ListItemText style={{ color: 'white', textDecoration: 'none' }} as={Link} to="/panel/tickets/list"
                        onClick={this.handleCloseDrawer} primary="تیکت" />
                </ListItem>

                {/* <Divider/>

                <ListItem button sx={{textAlign: 'right'}}>
                    <ListItemIcon as={Link} to="/panel/profile">
                        <AccountCircleIcon/>
                    </ListItemIcon>
                    <ListItemText style={{color: 'white'}} as={Link} to="/panel/profile" primary="پروفایل"/>
                </ListItem>

                <ListItem button sx={{textAlign: 'right'}}>
                    <ListItemIcon as={Link} to="/logout">
                        <LogoutIcon/>
                    </ListItemIcon>
                    <ListItemText style={{color: 'white'}} as={Link} to="/logout" primary="خروج"/>
                </ListItem>*/}
            </div>

        );

    }

}

export default NavbarList;

