import React from 'react';
import ReactSession from '../ReactSession';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import swal from 'sweetalert';
import { Form } from "react-bootstrap";
import LoginBack from "../img/loginBack.jpg"
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import { socket } from './../context/socket';
import CryptoJS from 'crypto-js'

// import header index


class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            BridgeSocket: this.props.BridgeSocket,
            resultLogin: null
        }
    }

    getCookie(name) {
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }


    settoken(e) {
        ReactSession.setStoreType("cookie");
        ReactSession.set("token", e);
    }

    handleChangePassword(e) {

        this.setState({ password: e.target.value });
    }

    ShowSucMessage(e) {
        swal(e);
    }

    ShowloginMessage(e, s) {
        swal(e, '', s);
    }


    handleChangeEmail(e) {

        this.setState({ email: e.target.value });
    }

    componentWillUnmount() {
        // stop sending ajax when client in other page
        this.mounted = false;

    }

    componentDidMount(props) {

        document.title = "ورود"
        this.mounted = true;
        this.setState({
            isLoaded: true,
        });
    }


    async LoginHandler(e) {
        //   this.setState({ fromInput: e.target.value })

        e.preventDefault();
        if (this.state.email == null && this.state.password == null) {
            this.ShowloginMessage('اطلاعاتی وارد نشده', 'error')
        } else {
            this.ShowloginMessage('در حال ورود', 'info');


            if (this.mounted) {

                socket.emit('auth', {
                    email: this.state.email,
                    password: this.state.password,
                    objectReq: 1
                })

                //username
                const username = await CryptoJS.AES.encrypt(JSON.stringify(await this.state.email), 'my-secrwehewhet-key@wjewjwej123').toString();
                await localStorage.setItem("user1", await username)
                //password
                const password = await CryptoJS.AES.encrypt(JSON.stringify(await this.state.password), 'my-secrwehewhet-key@wjewjwej123').toString();
                await localStorage.setItem("user2", await password)

                socket.on('auth/' + socket.id + '/' + 1, async (msg) => {
                    await localStorage.setItem("io", await msg.token)

                    if (msg.message === 'Invalid email or password.') {
                        this.ShowloginMessage('اطلاعات درست نیست', 'error');
                    }
                    if (msg.status) {
                        this.ShowloginMessage('با موفقیت وارد شدید', 'success');

                        // set token to seesion storage
                        //this.settoken(result.token);
                        window.location.href = "/panel/dashboard";
                        //   this.props.history.push("/panel/dashboard");
                    }

                })
            }
        }

    }


    render() {
        //   const { error, isLoaded, data, Loginmessage } = this.state;
        const theme = createTheme({
            direction: 'rtl',
            typography: {
                fontFamily: [
                    'GoldmanLight',
                ].join(','),
            },
        });

        return (
            <div style={{ height: '100vh' }}>
                <ThemeProvider theme={theme}>
                    <Grid container component="main" sx={{ height: '100vh' }}>
                        <CssBaseline />
                        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                            <Box
                                sx={{
                                    my: 8,
                                    mx: 4,
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    paddingTop: '5rem'
                                }}
                            >
                                {/*  <Avatar align='center' style={{textAlign:'center'}} sx={{m: 1, bgcolor: 'secondary.main',textAlign:'center'}}>
                                    <LockOutlinedIcon/>
                                </Avatar>*/}
                                <Typography component="h1" variant="h5">
                                    ورود
                                </Typography>
                                <Box component="form" noValidate sx={{ mt: 1 }}>

                                    <Form style={{ textAlign: 'right' }}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Label>ایمیل</Form.Label>
                                            <Form.Control style={{
                                                border: '1px solid #2860fc',
                                            }} type="text" placeholder="ایمیل را وارد کنید"
                                                onChange={this.handleChangeEmail.bind(this)} required />
                                        </Form.Group>

                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Label>رمز عبور</Form.Label>
                                            <Form.Control style={{
                                                border: '1px solid #2860fc',
                                            }} type="password" placeholder="رمز عبور را وارد کنید"
                                                onChange={this.handleChangePassword.bind(this)} required />
                                        </Form.Group>

                                        {/*<FormControlLabel
                                        control={<Checkbox value="remember" color="primary"/>}
                                        label="Remember me"
                                    />*/}
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            onClick={this.LoginHandler.bind(this)}
                                        >
                                            ورود
                                        </Button>
                                    </Form>
                                    <Grid container style={{ width: '20rem', textAlign: 'center' }}>
                                        {/*<Grid item xs>
                                            <Link href="#" variant="body2">
                                                Forgot password?
                                            </Link>
                                        </Grid>*/}
                                        <Grid item style={{ textAlign: 'center' }}>
                                            <Link as={Link}
                                                to={'/register'} style={{ textAlign: 'center' }} variant="body2">
                                                {"ثبت نام"}
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={false}
                            sm={4}
                            md={7}
                            sx={{
                                backgroundImage: `url(${LoginBack})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: (t) =>
                                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        />
                    </Grid>
                </ThemeProvider>
            </div>

        );
    }
}

//Date(item.time)


export default Login;
