import * as React from 'react';
import Container from '@mui/material/Container';
import { Button } from "@mui/material";
import Grid from '@mui/material/Grid';
import { Component } from "react";
import Paper from '@mui/material/Paper';
import { Alert, Form } from "react-bootstrap";
import Loading from "../Loading";
import { socket } from '../context/socket';

class TicketsDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            services: [{ title: 'test', active: true }],
            projectId: localStorage.projectId,
            themeColor: '#93BB34',
            ticket_id: localStorage.ticket_id,
            message: ''
        };
    }


    async SendMessage() {

        socket.emit('message', {
            app_id: 'demopanel',
            address: 'tickets/chat/send',
            role: 'user',
            token: await localStorage.io,
            side: {
                side: 'frontend'
            },
            data: {
                message: await this.state.message,
                ticket_id: await localStorage.ticket_id,
                socketId: await socket.id
            }
        })

        //get list tickets

    }


    async componentDidMount() {
        this.mounted = true;

        this.setState({
            isLoaded: true
        })

        setTimeout(async () => {

            const ticket_id = await localStorage.ticket_id

            socket.emit('message', {
                app_id: 'demopanel',
                address: 'title/set/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    message: 'تیکت ها'
                }
            })

            //get list tickets
            socket.emit('message', {
                app_id: 'demopanel',
                address: 'tickets/chat/list',
                role: 'user',
                token: await localStorage.io,
                side: {
                    side: 'frontend'
                },
                data: {
                    message: '',
                    ticket_id: await localStorage.ticket_id,
                    socketId: await socket.id
                }
            })
            //register to listen
            socket.emit('message', {
                app_id: 'demopanel',
                address: 'tickets/chat/list/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    text: '123',
                }
            })


            socket.on("message/demopanel/tickets/chat/list/" + await socket.id + '/user', async (msg) => {
                console.log(msg)
                if (await msg.data.chatlist != undefined) {
                    await this.setState({
                        services: await msg.data.chatlist,
                        isLoaded: false
                    })
                }
            })
        }, 1000)

        //set color theme
        // window.RayconnectApp.localEvents.emit("themeColor", '#93BB34');
        // window.RayconnectApp.localEvents.on('selectedProject', (projectId) => {
        //     this.setState({projectId})
        //     window.RayconnectApp.localEvents.emit("title", 'DBaaS Service - ' + projectId);
        // })
        if (window.innerWidth < 700) {
            //  window.RayconnectApp.localEvents.emit("toggleDrawer", true);
        }
    }

    handleSend(e) {
        this.setState({
            message: e.target.value,
        })
    }

    render() {

        const { services, isLoaded, projectId, themeColor } = this.state;
        // if (projectId === undefined || projectId === 'undefined') {
        //     return (<div><PleaseSelectProject /></div>);
        // } else {
        if (isLoaded) {
            return (<div><Loading /></div>);
        } else {

            return (
                <div>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>


                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto',
                                        textAlign: 'right'
                                    }}>



                                    {services.map((row) => (
                                        <Alert variant='primary'>
                                            {row.text}
                                        </Alert>
                                    ))}


                                    <Form.Group>
                                        <Form.Label>متن پیام</Form.Label>
                                        <Form.Control type="text" as="textarea" rows="3" onChange={this.handleSend.bind(this)} />
                                    </Form.Group>

                                    <Button variant="contained" style={{ marginTop: '1rem' }}
                                        onClick={this.SendMessage.bind(this)}>ارسال پیام</Button>

                                </Paper>

                            </Grid>
                        </Grid>
                    </Container>
                </div>
            );
        }
        // }
    }
}

export default TicketsDetail;
