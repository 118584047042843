import React, {Component} from 'react';

// import header index
import HeaderIndex from './Layouts/HeardeIndex';
//import css

import './Css/index.css';

// import react router
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from "@mui/material/styles";

import {Link as Lonk} from "react-router-dom";
import MouseIcon from '@mui/icons-material/Mouse';


class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }

    getCookie(name) {
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }

    componentWillUnmount() {
        // stop sending ajax when client in other page
        this.mounted = false;
    }

    componentDidMount(props) {

        this.mounted = true;
        document.title = "پنل رایدا"
        /* window.RayconnectApp.isAuth().then(() => {
            console.log(window.RayconnectApp.user);
        })*/

    }


    render() {

        const tiers = [
            {
                title: 'Free',
                price: '0',
                description: [
                    '10 users included',
                    '2 GB of storage',
                    'Help center access',
                    'Email support',
                ],
                buttonText: 'Sign up for free',
                buttonVariant: 'outlined',
            },
            {
                title: 'Pro',
                subheader: 'Most popular',
                price: '15',
                description: [
                    '20 users included',
                    '10 GB of storage',
                    'Help center access',
                    'Priority email support',
                ],
                buttonText: 'Get started',
                buttonVariant: 'contained',
            },
            {
                title: 'Enterprise',
                price: '30',
                description: [
                    '50 users included',
                    '30 GB of storage',
                    'Help center access',
                    'Phone & email support',
                ],
                buttonText: 'Contact us',
                buttonVariant: 'outlined',
            },
        ];

        const footers = [
            {
                title: 'Company',
                description: ['Team', 'History', 'Contact us', 'Locations'],
            },
            {
                title: 'Features',
                description: [
                    'Cool stuff',
                    'Random feature',
                    'Team feature',
                    'Developer stuff',
                    'Another one',
                ],
            },
            {
                title: 'Resources',
                description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
            },
            {
                title: 'Legal',
                description: ['Privacy policy', 'Terms of use'],
            },
        ];

        const mdTheme = createTheme({
            direction: 'rtl',
            typography: {
                fontFamily: [
                    'GoldmanLight',
                ].join(','),
            },
        });


        return (

            <div dir="rtl" style={{height: '100vh'}}>

                <ThemeProvider theme={mdTheme}>
                    {/*  <HeaderIndex/>

                <Container style={{height: '100% !important'}}>
                    <div style={{height: '1000px !important'}}>
                        <br></br> <br></br>
                        <br></br>
                        <h1 style={{textAlign: 'center', color: 'white', paddingTop: '5rem'}}>خدمات رایانش ابری
                            رایدا</h1>
                        <h5 style={{textAlign: 'center', paddingTop:'2rem'}}>NoBackend - Paas - Database - Storage - Edge - Backup - Domains</h5>
                    <h5>4:13 - 17-7-1400</h5>
                    </div>

                </Container>*/}

                    <GlobalStyles styles={{ul: {margin: 0, padding: 0, listStyle: 'none'}}}/>
                    <CssBaseline/>
                    <AppBar
                        position="static"
                        color="default"
                        elevation={0}
                        sx={{backgroundColor:'#f5f5f500',color:'white',borderBottom: (theme) => `1px solid ${theme.palette.divider}`}}
                    >
                        <Toolbar sx={{flexWrap: 'wrap'}}>
                            <Typography variant="h6" color="inherit" noWrap sx={{flexGrow: 1, textAlign: 'right'}}>
                                پلتفرم ابری رایدا
                            </Typography>
                           {/* <nav>
                                <Link
                                    variant="button"
                                    color="text.primary"
                                    href="#"
                                    sx={{my: 1, mx: 1.5}}
                                >
                                    <Lonk as={Lonk} to="/register">
                                    Features
                                    </Lonk>
                                </Link>
                                <Link
                                    variant="button"
                                    color="text.primary"
                                    href="#"
                                    sx={{my: 1, mx: 1.5}}
                                >
                                    Enterprise
                                </Link>
                                <Link
                                    variant="button"
                                    color="text.primary"
                                    href="#"
                                    sx={{my: 1, mx: 1.5}}
                                >
                                    Support
                                </Link>
                            </nav>*/}

                            <Lonk as={Lonk} to="/register">
                                <Button href="#" variant="outlined" sx={{my: 1, mx: 1.5, color:'white'}}>
                                    ثبت نام
                                </Button>
                            </Lonk>
                            <Lonk as={Lonk} to="/Login">
                            <Button href="#" variant="outlined" sx={{my: 1, mx: 1.5, color:'white'}}>
                                    ورود
                            </Button>
                            </Lonk>
                        </Toolbar>
                    </AppBar>
                    {/* Hero unit */}


                    <Container disableGutters maxWidth="sm" component="main" sx={{pt: 15, pb: 10, height:'100vh'}}>
                        <h1 style={{textAlign: 'center', color: 'white', paddingTop: '5rem'}}>خدمات رایانش ابری
                            رایدا</h1>
                        <h5 style={{textAlign: 'center', paddingTop:'2rem'}}>NoBackend - Paas - Database - Storage - Edge - Backup - Domains</h5>
                        <h5 style={{textAlign: 'center', paddingTop:'2rem'}}>1:06 - 4-8-1400</h5>
                        <div style={{textAlign: 'center', paddingTop:'8rem'}}> <MouseIcon/></div>
                    </Container>

                    {/* End hero unit */}


                    <Container disableGutters maxWidth="sm" component="main" sx={{pt: 8, pb: 6}}>
                        <Typography
                            component="h1"
                            variant="h2"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            Pricing
                        </Typography>
                        <Typography variant="h5" align="center" color="text.secondary" component="p">
                            Quickly build an effective pricing table for your potential customers with
                            this layout. It&apos;s built with default MUI components with little
                            customization.
                        </Typography>
                    </Container>
                    {/* End hero unit */}


                    <Container maxWidth="md" component="main">
                        <Grid container spacing={5} alignItems="flex-end">
                            {tiers.map((tier) => (
                                // Enterprise card is full width at sm breakpoint
                                <Grid
                                    item
                                    key={tier.title}
                                    xs={12}
                                    sm={tier.title === 'Enterprise' ? 12 : 6}
                                    md={4}
                                >
                                    <Card>
                                        <CardHeader
                                            title={tier.title}
                                            subheader={tier.subheader}
                                            titleTypographyProps={{align: 'center'}}
                                            action={tier.title === 'Pro' ? <StarIcon/> : null}
                                            subheaderTypographyProps={{
                                                align: 'center',
                                            }}
                                            sx={{
                                                backgroundColor: (theme) =>
                                                    theme.palette.mode === 'light'
                                                        ? theme.palette.grey[200]
                                                        : theme.palette.grey[700],
                                            }}
                                        />
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'baseline',
                                                    mb: 2,
                                                }}
                                            >
                                                <Typography component="h2" variant="h3" color="text.primary">
                                                    ${tier.price}
                                                </Typography>
                                                <Typography variant="h6" color="text.secondary">
                                                    /mo
                                                </Typography>
                                            </Box>
                                            <ul>
                                                {tier.description.map((line) => (
                                                    <Typography
                                                        component="li"
                                                        variant="subtitle1"
                                                        align="center"
                                                        key={line}
                                                    >
                                                        {line}
                                                    </Typography>
                                                ))}
                                            </ul>
                                        </CardContent>
                                        <CardActions>
                                            <Button fullWidth variant={tier.buttonVariant}>
                                                {tier.buttonText}
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                    {/* Footer */}
                    <Container
                        maxWidth="md"
                        component="footer"
                        sx={{
                            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                            mt: 8,
                            py: [3, 6],
                        }}
                    >
                        <Grid container spacing={4} justifyContent="space-evenly">
                            {footers.map((footer) => (
                                <Grid item xs={6} sm={3} key={footer.title}>
                                    <Typography variant="h6" color="text.primary" gutterBottom>
                                        {footer.title}
                                    </Typography>
                                    <ul>
                                        {footer.description.map((item) => (
                                            <li key={item}>
                                                <Link href="#" variant="subtitle1" color="text.secondary">
                                                    {item}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Grid>
                            ))}
                        </Grid>

                    </Container>

                </ThemeProvider>
            </div>

        )
    }
}

export default Index;
