import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Chart from './dashboard/Chart';
import Deposits from './dashboard/Deposits';
import { Component } from "react";
import Loading from "../Loading";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { socket } from './../context/socket';

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            BridgeSocket: this.props.BridgeSocket,
        };
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    componentWillUnmount() {
        // stop sending ajax when client in other page
        this.mounted = false;
    }

    componentWillMount() {
        setTimeout(() => {
            this.setState({ isLoaded: false })
        }, 1000);
    }

    async componentDidMount(props) {
        this.mounted = true;
        document.title = "داشبورد"

        setTimeout( async() => {
            socket.emit('message', {
                app_id: 'demopanel',
                address: 'title/set/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    message: 'داشبورد'
                }
            })
        }, 3000);

        setTimeout( async() => {
            socket.emit('message', {
                app_id: 'demopanel',
                address: 'title/set/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    message: 'داشبورد'
                }
            })
        }, 1000);

  
        // if (window.innerWidth < 700) {
        //     window.RayconnectApp.localEvents.emit("toggleDrawer", true);
        // }
    }


    render() {

        const bull = (
            <Box
                component="span"
                sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
            >
                •
            </Box>
        );


        const { isLoaded } = this.state;
        // window.RayconnectApp.localEvents.emit("themeColor", '#2860FC');

        if (isLoaded) {
            return <div><Loading /></div>;
        } else {

            return (
                <div dir="rtl">
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>


                        <Grid item xs={12}>
                            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                {/* <Orders/>*/}


                                <Box
                                    className='mx-auto'
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        p: 1,
                                        m: 1,
                                        bgcolor: 'background.paper',
                                    }}
                                >

                                    <Card className='mx-auto'
                                        as={Link} to="/panel/paas/list"
                                        sx={{
                                            minWidth: {
                                                xs: '12rem',
                                                md: '8rem'
                                            },
                                            marginLeft: {
                                                xs: '0',
                                                md: '0.2rem !important',
                                                lg: '0.2rem !important',
                                            },
                                            padding: '0.5rem',
                                            marginBottom: '0.5rem',
                                            fontSize: 14,
                                            backgroundColor: '#D64122',
                                            color: 'white',
                                            borderRadius: '10px',
                                            textDecoration: 'none'
                                        }}>
                                        <CardContent style={{ textDecoration: 'none!important' }}>
                                            <Typography
                                                sx={{ textDecoration: 'none', fontSize: 30, textAlign: 'center', backgroundColor: '#D64122', color: 'white' }}>
                                                PaaS
                                            </Typography>
                                            <Typography
                                                sx={{ textDecoration: 'none', fontSize: 20, textAlign: 'center', backgroundColor: '#D64122', color: 'white' }}>
                                                کانتینر ابری
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Card className='mx-auto'
                                        as={Link} to="/panel/dbaas/list"
                                        sx={{
                                            minWidth: {
                                                xs: '12rem',
                                                md: '8rem'
                                            },
                                            marginLeft: {
                                                xs: '0',
                                                md: '0.2rem !important',
                                                lg: '0.2rem !important',
                                            },
                                            padding: '0.5rem',
                                            marginBottom: '0.5rem',
                                            fontSize: 14,
                                            backgroundColor: '#93BB34',
                                            color: 'white',
                                            borderRadius: '10px',
                                            textDecoration: 'none'
                                        }}>
                                        <CardContent>
                                            <Typography
                                                sx={{ textDecoration: 'none', fontSize: 30, textAlign: 'center', backgroundColor: '#93BB34', color: 'white' }}>
                                                DBaaS
                                            </Typography>
                                            <Typography
                                                sx={{ textDecoration: 'none', fontSize: 20, textAlign: 'center', backgroundColor: '#93BB34', color: 'white' }}>
                                                دیتابیس ابری
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Card className='mx-auto'
                                        as={Link} to="/panel/domains"
                                        sx={{
                                            minWidth: {
                                                xs: '12rem',
                                                md: '8rem'
                                            },
                                            marginLeft: {
                                                xs: '0',
                                                md: '0.2rem !important',
                                                lg: '0.2rem !important',
                                            },
                                            padding: '0.5rem',
                                            marginBottom: '0.5rem',
                                            fontSize: 14,
                                            backgroundColor: '#2F5596',
                                            color: 'white',
                                            borderRadius: '10px',
                                            textDecoration: 'none'
                                        }}>
                                        <CardContent>
                                            <Typography
                                                sx={{ textDecoration: 'none', fontSize: 30, textAlign: 'center', backgroundColor: '#2F5596', color: 'white' }}>
                                                Domains
                                            </Typography>
                                            <Typography
                                                sx={{ textDecoration: 'none', fontSize: 20, textAlign: 'center', backgroundColor: '#2F5596', color: 'white' }}>
                                                مدیریت دامنه
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Card className='mx-auto'
                                        as={Link} to="/panel/static/list"
                                        sx={{
                                            minWidth: {
                                                xs: '12rem',
                                                md: '8rem'
                                            },
                                            marginLeft: {
                                                xs: '0',
                                                md: '0.2rem !important',
                                                lg: '0.2rem !important',
                                            },
                                            padding: '0.5rem',
                                            marginBottom: '0.5rem',
                                            fontSize: 14,
                                            backgroundColor: '#D64122',
                                            color: 'white',
                                            borderRadius: '10px',
                                            textDecoration: 'none'
                                        }}>
                                        <CardContent>
                                            <Typography
                                                sx={{ textDecoration: 'none', fontSize: 30, textAlign: 'center', backgroundColor: '#D64122', color: 'white' }}>
                                                Static
                                            </Typography>
                                            <Typography
                                                sx={{ textDecoration: 'none', fontSize: 20, textAlign: 'center', backgroundColor: '#D64122', color: 'white' }}>
                                                سایت استاتیک
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Card className='mx-auto'
                                        as={Link} to="/panel/storage/list"
                                        sx={{
                                            minWidth: {
                                                xs: '12rem',
                                                md: '8rem'
                                            },
                                            marginLeft: {
                                                xs: '0',
                                                md: '0.2rem !important',
                                                lg: '0.2rem !important',
                                            },
                                            padding: '0.5rem',
                                            marginBottom: '0.5rem',
                                            fontSize: 14,
                                            backgroundColor: '#ED9C45',
                                            color: 'white',
                                            borderRadius: '10px',
                                            textAlign: 'center',
                                            textDecoration: 'none'
                                        }}>
                                        <CardContent>
                                            <Typography as={Link} to="/panel/storage/list"
                                                sx={{
                                                    fontSize: 30,
                                                    backgroundColor: '#ED9C45',
                                                    color: 'white',
                                                    textAlign: 'center',
                                                    textDecoration: 'none',
                                                }}>
                                                Storage
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 20,
                                                    textAlign: 'center',
                                                    backgroundColor: '#ED9C45',
                                                    color: 'white',
                                                    textDecoration: 'none',
                                                }}>
                                                فضای ابری
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                    <Card className='mx-auto'
                                        as={Link} to="/panel/edge"
                                        sx={{
                                            minWidth: {
                                                xs: '12rem',
                                                md: '8rem'
                                            },
                                            marginLeft: {
                                                xs: '0',
                                                md: '0.2rem !important',
                                                lg: '0.2rem !important',
                                            },
                                            padding: '0.5rem',
                                            marginBottom: '0.5rem',
                                            fontSize: 14,
                                            backgroundColor: '#31589C',
                                            color: 'white',
                                            borderRadius: '10px',
                                            textDecoration: 'none'
                                        }}>
                                        <CardContent>
                                            <Typography
                                                sx={{ textDecoration: 'none', fontSize: 30, textAlign: 'center', backgroundColor: '#31589C', color: 'white' }}>
                                                Edge
                                            </Typography>
                                            <Typography
                                                sx={{ textDecoration: 'none', fontSize: 20, textAlign: 'center', backgroundColor: '#31589C', color: 'white' }}>
                                                لبه های ابری
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                </Box>

                            </Paper>
                        </Grid>

                        <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                            {/* Chart */}
                            <Grid item xs={12} md={8} lg={9}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <Chart />
                                </Paper>
                            </Grid>
                            {/* Recent Deposits */}
                            <Grid item xs={12} md={4} lg={3}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 240,
                                    }}
                                >
                                    <Deposits />
                                </Paper>
                            </Grid>
                        </Grid>

                    </Container>
                </div>
            );
        }
    }
}

export default Dashboard;
