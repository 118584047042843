import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Component } from "react";
import HeaderPanel from "./Layouts/HeaderPanel";
import Dashboard from "./Dashboard";
import queryString from 'query-string';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import TicketsList from "./TicketsList";
import Loading from "../Loading";
import TicketsDetail from "./TicketsDetail";
import TicketsAdd from "./TicketsAdd";
import { socket } from './../context/socket';


class IndexDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            hidePage: false,
            BridgeSocket: props.BridgeSocket,
            location: props.location,
            title: ''
        };
    }

    getCookie(name) {
        var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
        result && (result = JSON.parse(result[1]));
        return result;
    }

    async componentWillUnmount() {
        // stop sending ajax when client in other page
        this.mounted = false;
    }

    async componentDidMount(props) {
        this.mounted = true;
        document.title = "داشبورد"

        // window.RayconnectApp.localEvents.on('selectedProject', (projectId) => {
        //     localStorage.projectId = projectId;
        // })
        // if (window.innerWidth < 700) {
        //     window.RayconnectApp.localEvents.emit("toggleDrawer", true);
        // }
        setTimeout(async () => {
            socket.on("message/demopanel/hidePageWhenMobileDrawerOpen/set/" + await socket.id + '/user', status => {
                if (status.message != '') {
                    if (status.data.message) {
                        this.setState({ hidePage: true })
                    } else {
                        this.setState({ hidePage: false })
                    }
                }
                console.log('user call hide page 2')
            });
        }, 1000)
    }

    render() {



        const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
        const mdTheme = createTheme({
            direction: 'ltr',
            typography: {
                fontFamily: [
                    'GoldmanLight',
                ].join(','),
            },
        });
        const { hidePage, isLoaded, BridgeSocket, title } = this.state;

        //its undefined shoud fix it
        const paramsUrl = queryString.parse(this.state.location.search)
        //   const paramsUrl = 'localhost:3000'


        return (
            <div dir="rtl">

                <ThemeProvider theme={mdTheme}>
                    <StylesProvider jss={jss}>
                        {isLoaded &&
                            <Loading />
                        }
                        {!isLoaded &&
                            <Box sx={{ display: 'flex', backgroundColor: '#F5F5F5!important' }}>
                                <CssBaseline />
                                <HeaderPanel paramsUrl={paramsUrl} BridgeSocket={BridgeSocket} title={title} that={this} />

                                {!hidePage &&
                                    <Box component="main" sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[100]
                                                : theme.palette.grey[900],
                                        flexGrow: 1,
                                        height: '100vh',
                                        overflow: 'auto',
                                    }}>
                                        <Toolbar />

                                        {this.props.location.pathname === '/panel/dashboard' &&
                                            <Dashboard BridgeSocket={BridgeSocket} />
                                        }
                                        {this.props.location.pathname === '/panel/tickets/list' &&
                                            <TicketsList BridgeSocket={BridgeSocket} />
                                        }
                                        {this.props.location.pathname === '/panel/tickets/detail' &&
                                            <TicketsDetail BridgeSocket={BridgeSocket} />
                                        }
                                           {this.props.location.pathname === '/panel/tickets/create' &&
                                            <TicketsAdd BridgeSocket={BridgeSocket} />
                                        }
                                    </Box>
                                }
                            </Box>
                        }
                    </StylesProvider>
                </ThemeProvider>

            </div>
        )
            ;

    }
}

export default IndexDashboard;
