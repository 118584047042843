import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Component } from "react";
import { Form } from "react-bootstrap";
import { Alert, Button } from "@mui/material";
import Loading from "../Loading";
import { socket } from '../context/socket';


class TicketsAdd extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            projectStatus: '',
            appName: '',
            typeApp: '',
            projectId: localStorage.projectId,
            mariadbColor: '',
            mssqlColor: '',
            postgresColor: '',
            mysqlColor: '',
            redisColor: '',
            mongoColor: '',
            responseArray: [],
            response: '',
            cpu: 1,
            ram: 2,
            storage: 20,
            des: ''
        };
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    async componentDidMount(props) {

        this.mounted = true;

            setTimeout(async () => {

                socket.emit('message', {
                    app_id: 'demopanel',
                    address: 'title/set/' + await socket.id,
                    role: 'user',
                    token: await localStorage.io,
                    data: {
                        message: 'ایجاد تیکت جدید'
                    }
                })
 
             ////////// send created alert
             socket.on("message/demopanel/tickets/create/" + await socket.id + '/user', async (msg) => {
                socket.emit('message', {
                    app_id: 'demopanel',
                    address: 'alert/set/' + await socket.id,
                    role: 'user',
                    token: await localStorage.io,
                    data: {
                        status: 'created',
                        socketId: await socket.id
                    }
                })

            });

        }, 2000);

        // document.title = "ایجاد سرویس DBaaS"
        // window.RayconnectApp.localEvents.emit("themeColor", '#93BB34');
        // window.RayconnectApp.localEvents.emit("title", 'DBaaS Service Create - ' + localStorage.projectId);
        // window.RayconnectApp.localEvents.on('selectedProject', (projectId) => {
        //     this.setState({projectId})
        //     window.RayconnectApp.localEvents.emit("title", 'DBaaS Service Create- ' + projectId);
        // })
        // if (window.innerWidth < 700) {
        //     window.RayconnectApp.localEvents.emit("toggleDrawer", true);
        // }

    }

    handleAppName(e) {
        this.setState({
            appName: e.target.value,
        })
    }

    handleDes(e) {
        this.setState({
            des: e.target.value,
        })
    }

    async sendCreateApp(e) {


        setTimeout(async () => {
            //get list tickets
            socket.emit('message', {
                app_id: 'demopanel',
                address: 'tickets/create',
                role: 'user',
                token: await localStorage.io,
                side: {
                    side: 'frontend'
                },
                data: {
                    message: {
                        title: await this.state.appName,
                        des: await this.state.des,
                    },
                    socketId: await socket.id
                }
            })

            socket.emit('message', {
                app_id: 'demopanel',
                address: 'tickets/create/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    message: {
                        title: await this.state.appName,
                        des: await this.state.des,
                    },
                    socketId: await socket.id
                }
            })

        }, 1000)

        // try {
        //     window.RayconnectApp.isAuth().then(async () => {
        //         if (this.state.appName !== '' && this.state.typeApp !== '') {
        //             this.setState({
        //                 isLoaded: true
        //             })
        //             const result = await window.RayconnectApp.Run({
        //                 address: 'db/create',
        //                 method: 'POST',
        //                 scope: 'db',
        //                 data: {
        //                     aid: localStorage.projectId,
        //                     name: this.state.appName,
        //                     type: this.state.typeApp,
        //                     cpu: this.state.cpu,
        //                     ram: this.state.ram,
        //                     storage: this.state.storage,
        //                 },
        //             })
        //             console.log(result)
        //             if (result.data.status) {
        //                 await window.RayconnectApp.localEvents.emit('alert', 'created')
        //                 this.setState({
        //                     projectStatus: true,
        //                     open: true,
        //                     isLoaded: false,
        //                     responseArray: result.data.response.inputs,
        //                     response: result.data.response
        //                 })
        //             } else {
        //                 await window.RayconnectApp.localEvents.emit('alert', 'error')
        //                 this.setState({
        //                     projectStatus: false,
        //                     isLoaded: false
        //                 })
        //             }
        //         } else {
        //             await window.RayconnectApp.localEvents.emit('alert', 'empty')
        //         }

        //     })
        // } catch (e) {
        //     console.log(e)
        // }
    }

    render() {

        // window.RayconnectApp.localEvents.emit("themeColor", '#93BB34');

        const {
            projectStatus,
            isLoaded,
            typeApp,
            mariadbColor,
            mssqlColor,
            postgresColor,
            mysqlColor,
            redisColor,
            mongoColor,
            responseArray,
            response,
            storage,
            cpu,
            ram,
        } = this.state;

        if (isLoaded) {
            return (<div><Loading /></div>);
        } else {

            return (
                <div>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto',
                                    }}>

                                    {projectStatus &&
                                        <Grid item xs={12} md={8} lg={8} sx={{ textAlign: 'right' }}>

                                            <Alert severity={response.alert.type}>{response.alert.message}</Alert>

                                            {responseArray.map(this.arrayListShow)}
                                        </Grid>
                                    }

                                    {!projectStatus &&
                                        <Grid item xs={12} md={8} lg={8} sx={{ textAlign: 'right' }}>

                                            <Form.Group>
                                                <Form.Label>عنوان</Form.Label>
                                                <Form.Control type="text" onChange={this.handleAppName.bind(this)} />
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>توضیحات</Form.Label>
                                                <Form.Control type="text" as="textarea" rows="3" onChange={this.handleDes.bind(this)} />

                                            </Form.Group>


                                            <Button variant="contained" sx={{
                                                '&:hover': {
                                                    backgroundColor: this.state.themeColor,
                                                }, backgroundColor: this.state.themeColor + '!important'
                                            }} style={{ marginTop: '1rem', backgroundColor: this.state.themeColor + '!important' }}
                                                onClick={this.sendCreateApp.bind(this)}>ایجاد تیکت</Button>
                                        </Grid>
                                    }

                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            );
        }
    }
}

export default TicketsAdd;
