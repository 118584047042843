import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Component } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Form } from "react-bootstrap";
import { Button, Divider, Stack } from "@mui/material";
import Loading from "../Loading";
import Alert from '@mui/material/Alert';
import { Link } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PleaseSelectProject from "./PleaseSelectProject";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { socket } from '../context/socket';

class TicketsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            services: [{ title: 'test', active: true }],
            projectId: localStorage.projectId,
            themeColor: '#93BB34',
        };
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    async componentDidMount() {
        this.mounted = true;

        this.setState({
            isLoaded: true
        })

        setTimeout(async () => {

            socket.emit('message', {
                app_id: 'demopanel',
                address: 'title/set/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    message: 'تیکت ها'
                }
            })


            //get list tickets
            socket.emit('message', {
                app_id: 'demopanel',
                address: 'tickets/list',
                role: 'user',
                token: await localStorage.io,
                side: {
                    side: 'frontend'
                },
                data: {
                    message: '',
                    socketId: await socket.id
                }
            })
            //register to listen
            socket.emit('message', {
                app_id: 'demopanel',
                address: 'tickets/list/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    text: '123',
                }
            })


            socket.on("message/demopanel/tickets/list/" + await socket.id + '/user', async (msg) => {
                if (msg.data.list != undefined) {
                    await this.setState({
                        services: await msg.data.list,
                        isLoaded: false
                    })
                }
            })
        }, 1000)

        setTimeout( async() => {
            socket.emit('message', {
                app_id: 'demopanel',
                address: 'title/set/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    message: 'تیکت ها'
                }
            })
              //register to listen
            socket.emit('message', {
                app_id: 'demopanel',
                address: 'tickets/list/' + await socket.id,
                role: 'user',
                token: await localStorage.io,
                data: {
                    text: '123',
                }
            })
        }, 3000)

        //set color theme
        // window.RayconnectApp.localEvents.emit("themeColor", '#93BB34');
        // window.RayconnectApp.localEvents.on('selectedProject', (projectId) => {
        //     this.setState({projectId})
        //     window.RayconnectApp.localEvents.emit("title", 'DBaaS Service - ' + projectId);
        // })
        if (window.innerWidth < 700) {
            //  window.RayconnectApp.localEvents.emit("toggleDrawer", true);
        }
    }

    render() {

        const { services, isLoaded, projectId, themeColor } = this.state;
        // if (projectId === undefined || projectId === 'undefined') {
        //     return (<div><PleaseSelectProject /></div>);
        // } else {
        if (isLoaded) {
            return (<div><Loading /></div>);
        } else {

            return (
                <div>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>


                                <Paper
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 'auto',
                                        textAlign: 'right'
                                    }}>

                                    <Stack
                                        direction="row"
                                        divider={<Divider orientation="vertical" flexItem />}
                                        spacing={2}
                                    >
                                        <Alert severity="info">تیکت ها</Alert>
                                        <Link as={Link} to="/panel/tickets/create"><Button variant="contained"
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: this.state.themeColor,
                                                }, width: 'maxContent', backgroundColor: this.state.themeColor
                                            }}>تیکت جدید</Button></Link>
                                    </Stack>

                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">عنوان</TableCell>
                                                    <TableCell align="center">وضعیت</TableCell>
                                                    <TableCell style={{ textAlign: 'center' }}>عملیات</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {services.map((row) => (
                                                    <TableRow
                                                        key={row._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell style={{ textAlign: 'left' }}>
                                                            <Form.Control style={{ textAlign: 'left' }} type="text"
                                                                defaultValue={row.title}
                                                                readOnly={true} />
                                                        </TableCell>

                                                        <TableCell style={{ textAlign: 'left' }}>
                                                            <Form.Control style={{ textAlign: 'left' }} type="text"
                                                                defaultValue={row.active ? 'فعال' : 'غیر فعال'}
                                                                readOnly={true} />
                                                        </TableCell>

                                                        <TableCell style={{ textAlign: 'center' }} component="th"
                                                            scope="row">

                                                            <Link as={Link} onClick={async () => await localStorage.setItem("ticket_id", await row._id)} to="/panel/tickets/detail">
                                                                <VisibilityIcon style={{ margin: '0.2rem' }} />
                                                            </Link>
                                                            < DeleteForeverIcon style={{ margin: '0.2rem' }} />
                                                            <PowerSettingsNewIcon style={{ margin: '0.2rem' }} />

                                                        </TableCell>
                                                    </TableRow>
                                                ))}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </Paper>

                            </Grid>
                        </Grid>
                    </Container>
                </div>
            );
        }
        // }
    }
}

export default TicketsList;
