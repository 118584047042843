import * as React from 'react';
import {useTheme} from '@mui/material/styles';
import {BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import Title from './Title';

const data = [
    {
        name: 'آبان',
        cash: 2400,
    },
    {
        name: 'آذر',
        cash: 1398,
    },
    {
        name: 'دی',
        cash: 9800,
    },
    {
        name: 'بهمن',
        uv: 2780,
        cash: 3908,
        amt: 2000,
    },
    {
        name: 'اسفند',
        uv: 1890,
        cash: 4800,
        amt: 2181,
    },
    {
        name: 'فروردین',
        uv: 2390,
        cash: 3800,
        amt: 2500,
    },
    {
        name: 'اردیبهشت',
        uv: 3490,
        cash: 4300,
        amt: 2100,
    },
    {
        name: 'خرداد',
        uv: 4000,
        cash: 2400,
        amt: 2400,
    },
    {
        name: 'تیر',
        uv: 2780,
        cash: 3908,
        amt: 2000,
    },
    {
        name: 'مرداد',
        uv: 1890,
        cash: 4800,
        amt: 2181,
    },
    {
        name: 'شهریور',
        uv: 2390,
        cash: 3800,
        amt: 2500,
    },
    {
        name: 'مهر',
        uv: 3490,
        cash: 4300,
        amt: 2100,
    },
];

export default function Chart() {
    const theme = useTheme();

    return (
        <React.Fragment>
            <h6 style={{textAlign: 'right'}}>مصرف موجودی ریالی</h6>
            <ResponsiveContainer width="100%" height="100%" style={{direction: 'ltr !important'}}>
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 1,
                            left: 1,
                            bottom: 5,
                        }}
                        sx={{direction: 'ltr!important'}}
                    >
                        <CartesianGrid strokeDasharray="3 3" style={{direction: 'ltr!important'}}/>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <Tooltip/>
                        <Legend/>
                        <Bar dataKey="cash" fill="#2860FC"/>
                        {/*        <Bar dataKey="uv" fill="#82ca9d" />*/}
                    </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
