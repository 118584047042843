import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import {Button} from "@mui/material";

function preventDefault(event) {
  event.preventDefault();
}

export default function Deposits() {
  return (
    <React.Fragment>
      <Title>اعتبار شما</Title>
      <Typography component="p" style={{paddingTop:'1.5rem'}} align="right" variant="h4">
       10,500 ریال
      </Typography>
      <div style={{paddingTop:'2rem' , textAlign:'right'}}>
          <Button variant="contained">افزایش موجودی</Button>
      </div>
    </React.Fragment>
  );
}
